body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  --brown: #2d1d13;
  --white: #ffff;
  --red: #ef4444;
  --neutral: #fafaf9;
  --border: #ededed;
  --weightBorder :#d3d3d3;
  --black: #000;
  --color1:#d9bca9;
  --gray:#f1f1f1;
  --gray2:#f5f5f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration:unset;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.spinner {
  animation: rotate 2s linear infinite;
  font-size: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}