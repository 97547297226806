.d-principal-row {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    padding-top: 30px;
}

.dashboard-box {
    max-width: max-content;
    padding: 25px;
    border: 2px solid var(--border);
    margin: 3% auto;
    border-radius: 5px;
    transition: 0.3s ease;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    line-height: 1.5;
}

.dashboard-box:hover {
    border: 3px solid var(--brown);
}

.dashboard-box a {
    color: var(--brown);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    cursor: pointer;
}

.dashboard-box a svg {
    font-size: 40px;
    padding: 0 5px;
}

.dashboard-box a {
    font-size: 25px;
}

.product-dashboard-container {
    display: flex;
    flex-wrap: wrap;
    padding: 2%;
}

.product-dashboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    border: 2px solid var(--gray);
    padding: 10px;
    margin: 10px;
    transition: 0.3s ease;
    cursor: pointer;
    color: black;
}

.edit-popup {
    z-index: 5;
}

.product-dashboard:hover {
    border: 2px solid var(--brown);
}

.button-row button,
.button-row a,
.create-product {
    margin-right: 10px;
    margin-top: 15px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: var(--brown);
    color: var(--white);
    cursor: pointer;
    font-weight: bold;
    transition: 0.3s ease;
    font-size: 15px;
    display:block;
    text-align: center;
}

.button-row button:hover,
.button-row a:hover,
.create-product:hover {
    opacity: 0.8;
}

.form-create form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #f5efea;
    padding: 10px;
}

.popup-overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.edit-popup h2,
.edit-popup b {
    padding-left: 10px;
}

.form-create form input,
.edit-popup form input {
    margin: 5px;
    padding: 5px;
    font-weight: bold;
    transition:0.3s ease;
}

.form-create form textarea,
.edit-popup form textarea {
    margin: 5px;
    width: 292px;
    height: 102px;
    padding: 10px;
    font-size: 14px;
    color: rgb(51, 51, 51);
    border-radius: 5px;
    resize: vertical;
    font-weight: bold;

}

.form-create b,
.form-create p {
    padding-left: 10px;
    padding-top: 10px;
}



.form-create #btnEnviar,
.form-edit #btnEnviar {
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: var(--brown);
    color: var(--white);
    cursor: pointer;
    transition: 0.3s ease;
    font-weight: bold;
    transition: 0.3s ease;
}

.form-create #btnEnviar:hover,
.form-edit #btnEnviar:hover {
    opacity: 0.8;
}

.edit-popup {
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
}

.edit-popup form {
    background: var(--gray);
    display: flex;
    flex-direction: column;
    padding: 3%;
    z-index: 6;
    border-radius: 5px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.edit-popup form input,
.edit-popup form textarea {
    margin-top: 10px;
    margin-bottom: 10px;
    transition:0.3s ease;
}

@media (min-width:921px) {
    .dashboard-dashboard {
        height: 80vh;
    }

    .dashboard-wrapper {
        position: relative;
    }

    .form-create {
        padding-top: 30px;
        padding-right: 50px;
    }

    .form-create form input {
        width: 300px;
    }
}


/* Product manager */

.dashboard-product-container {
    height: 100%;
    background: var(--color1);
    display: grid;
    place-content: center;
}


.dashboard-product-wrapper,
.dashboard-banner-wrapper {
    background: var(--white);
    padding: 10px 20px;
    margin: 20px;
    width: 80vw;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border-radius: 10px;
    min-height: 80vh;
    position: relative;
}

.gallery-images {
    display: flex;
    justify-content: center;
}

.gallery-images img {
    width: 300px;
}

.form-row,
.images-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 60%;
    margin: auto;
}


.row-buttons {
    margin: auto 20px 10px 20px;
    display: flex;
    padding-top: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.row-buttons a {
    background: #eab308;
    color: white;
    padding: 10px 25px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
}

.row-buttons input {
    color: white;
    background: #84cc16;
    padding: 10px 25px;
    font-weight: bold;
    border-radius: 10px;
    border: unset;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s ease;
}

.yellow-btn {
    background: #f87171 !important;
}

.row-buttons a:hover,
.row-buttons input:hover {
    opacity: 0.8;
}

.form-row label,
.images-row label {
    font-size: 1.1em;
    font-weight: bold;
    padding-bottom: 10px;
    padding-top: 10px;
}

.form-row input, .form-row select {
    min-width: 20vw;
    padding: 10px 5px;
    border-radius: 10px;
    border: 2px solid var(--weightBorder);
}

.form-row textarea {
    min-width: 20vw;
    padding: 15px 10px;
    border-radius: 10px;
    border: 2px solid var(--weightBorder);
    min-height: 100px;
    line-height: 1.5;
}


.images-row input {
    min-width: 20vw;
    padding: 10px 5px;
    border-radius: 10px;
    max-width: max-content;
    margin-top: 10px;
    border: 2px solid var(--weightBorder);
    transition:0.3s ease;
}

.images-row b {
    padding-top: 10px;
}

.preview-row {
    display: flex;
    align-items: center;
    gap: 20px;
}


@media (min-width:921px) {
    .gallery-images img {
        margin: 0 15px;
        padding-bottom: 20px;
        border-radius: 5px;
        padding-top: 15px;
    }
}

@media (max-width:920px) {
    .dashboard-dashboard {
        height: 100%;
    }

    .product-dashboard-container {
        justify-content: center;
    }

    .form-row textarea {
        width: auto;
        max-width: max-content;
    }

    .gallery-images {
        overflow-x: scroll;
        justify-content: unset;
    }

    .gallery-images img {
        width: 100%;
    }

    .form-row,
    .images-row {
        max-width: 100%;
    }

    .form-row textarea {
        width: 100%;
        max-width: 100%;
    }

    .form-row textarea {
        padding: 15px 5px;
    }
}



/* warn popup */

.pp-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}


.popup-warn {
    padding: 30px;
    background: var(--white);
    border-radius: 10px;
    display: flex;
    place-content: center;
    min-width: 250px;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    animation: fade-in 0.3s ease;
}

.popup-warn button {
    color: white;
    padding: 10px 25px;
    font-weight: bold;
    border-radius: 10px;
    border: unset;
    font-size: 15px;
    cursor: pointer;
    transition: 0.3s ease;
    margin: 0 10px;
    transition: 0.3s ease;
}

.popup-warn button:hover {
    opacity: 0.8;
}

.accept {
    background: #84cc16;
}

.cancel {
    background: #f87171;
}

.popup-info {
    line-height: 1.5;
}

.popup-info p {
    margin-top: 0;
    padding: 10px;
}

.dashboard-box {
    & p {
        text-align: center;
        }
}

.completed-product {
    & input,
    & textarea {
        opacity: 0.7;
        pointer-events: none;
    }
}


@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.dashboard-flex {
    display:flex;
    flex-wrap:wrap;
}


.dashboard-banner-wrapper h3 {
    text-align: center;
}