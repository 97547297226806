.card-wrapper {
    max-width: 80vw;
    margin:0 auto 30px;
    height:100%;
    background:white;
    padding:2% 0 0 ;
    display:flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;
    flex-direction: column;
}

.card-title {
    font-size: 1.5em;
    text-align: center;
    padding-top:15px;
}

.card-wrapper .group_title hr {
    display:none;
}

.product-img {
    max-width:400px;
}

.product-card:has(.card-wrapper) {
    background:#f1f1f1;
}

.card-container {
    display:flex;
}

.product-info {
    padding:0 5%;
    display:flex;
    flex-direction:column;
}
.btnConsultar {
    padding:8px;
    color:var(--white);
    max-width: max-content;
    background:var(--brown);
    text-decoration:none;
    margin-top:20px;
    border-radius:5px;
    transition:0.2s ease;
    display:flex;
    align-items: center;
    border:unset;
}
.btnConsultar:hover {
    opacity:0.8;
}

.btnConsultar svg {
    font-size:25px;
    padding-left:10px;
}

.swiper-button-next,
.swiper-button-prev {
    background: white;
    border-radius: 100%;
    min-width: 45px;
    height: 45px !important;
    color: black;
    font-size: 12px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border: gray;
}
.swiper-button-next:after,
.swiper-button-prev:after {
    font-size:25px !important;
    color:black;
    font-weight:bold;
}
.card-container .swiper-pagination-bullet {
    background:var(--white);
}

.dropdown {
    padding-top:20px;
    transition:0.5s ease;
    position:relative;
    line-height: 1.5;
    height:25px;
    transition:0.3s ease;
}

.dropdown.active {
    height:100%;
}

.dropdown .ico {
    position:absolute;
    right:0;
}

.dropdown .ico::before {
    content:"+";
}

.dropdown.active .ico::before {
    content:"-";
}

.dropdown span {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease; 
    font-size:15px;
}

.dropdown.active span {
    opacity: 1;
    pointer-events: all;
}

.dropdown b {
    font-size:18px;
    cursor:pointer;
}

.product-info p {
    line-height:1.6;
}
.card-wrapper{
    min-height:60vh;
}
.card-categories{
    display:flex;
    align-items: center;
    margin-right:auto;
    margin-bottom:15px;
    width:100%;
}

.product-card-catalog {
    margin-left:auto;
    color:black;
    background: var(--gray);
    padding:5px 10px;
    border-radius: 5px;
}

.card-categories svg {
    color:var(--brown);
}

.card-category {
    padding: 5px 10px;
    background: var(--gray);
    margin: 0 5px 0px;
    border-radius: 5px;
    cursor: pointer;
}


@media (min-width:1024px) {
   
    .btnConsultar {
        width:300px;
    }
    .product-info p {
        max-width:470px;
    }
    .product-info {
        margin-left:30px;
        background:var(--gray);
        border-radius:5px;
        padding-bottom:20px;
        height:max-content;
    }

}

@media (max-width:1023px){
  
    .product-img {
        max-width:300px;
    }
    .card-container {
        flex-direction: column;
    }
    .card-wrapper {
        max-width:90%;
    }
    .card-container {
        justify-content:center;
        align-items: center;
    }
    .btnConsultar {
        padding:8px ;
        font-size: 13px;
    }
    .dropdown {
        height:30px;
    }
}


@media (max-width:640px) {
    .card-categories {
        margin-top:10px;
    }
    .card-category,
    .product-card-catalog {
        font-size:12px;
    }
}