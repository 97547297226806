.login-container {
    background:rgb(129 99 78 / 80%);
    height:100vh;
    display:flex;
    justify-content: center;
    align-items: center;
}

.login-container form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
    padding: 3% 1%;
    border-radius: 10px;
    background: var(--color1);
    height: max-content;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    width: 320px;
    border: 2px solid var(--gray);
}

.login-container label {
    text-align: center;
    font-weight:bold;
    padding-bottom:30px;
    font-size:2.2em;
    color:var(--brown);
}

.login-container .btnEnviar {
    cursor:pointer;
    font-weight:bold;
    transition:0.3s ease;
}

.login-container .btnEnviar:hover {
    color:var(--white);
    background:var(--brown);
}

.login-container input {
    margin:5px;
    padding:11px 10px;
    border:2px solid var(--neutral);
    color:var(--white);
    font-weight:bold;
    background:#d3b6a4;
}

.login-container input::placeholder {
    color:white;
}


@media (max-width:640px) {
    .login-container label {
        font-size:1.8em;
        padding-top:20px;
    }
}