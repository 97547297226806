/* popup */

.pp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top:0;
    bottom:0;
}

.pp-content form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9;
}

.pp-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 6;
    background: rgba(0, 0, 0, 0.5);
}

.pp-content {
    display: flex;
    flex-direction: column;
    position: fixed;
    background: var(--gray2);
    padding: 20px;
    z-index: 7;
    border-top: 7px solid var(--brown);
    width: 320px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    animation: fade-in 0.5s forwards; 
}

.pp-close {
    max-width: max-content;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 25px;
    cursor: pointer;
    background: var(--brown);
    display: flex;
    align-items: center;
}

.pp-close svg {
    filter: invert();
}

.pp-content input {
    display: block;
    margin: 15px 0 0px 0;
    width: 100%;
    height: 35px;
    border: 2px solid var(--gray);
    padding: 5px 7px;
    border-radius: 10px;
}

.pp-content #btnEnviar {
    width: 100%;
    background: var(--brown);
    color: var(--white);
    font-weight: bold;
    border-radius: 5px;
    font-size: 18px;
    height:50px;
}

.pp-content #btnEnviar {
    cursor: pointer;
    transition: 0.3s ease;
}

.pp-content #btnEnviar:hover {
    opacity: 0.8;
}

.pp-content textarea {
    width: 100%;
    margin-top: 10px;
    border: 2px solid var(--gray);
    font-family: 'Poppins', sans-serif;
    padding: 5px 7px;
    max-width: 320px;
    border-radius:10px;
}

.pp-content .hidden-data {
    display: none;
}

.btns-container {
    display: flex;
    align-items: center;
}

.btns-container a {
    margin-right: 10px;
}

.btnConsultar {
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
}

.fade-in {
    animation: fade-in 0.5s;
}

.fade-out {
    animation: fade-out 0.5s;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 420px) {
    .pp-content {
        max-width: max-content;
    }
}
