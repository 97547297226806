.footer-wrapper {
    background: var(--brown);
    color: white;
    width: 100%;
}


.footer-content {
    max-width:80%;
    width:100%;
    margin:0 auto ;
    padding-bottom:40px;
    padding-top:40px;
}


.logo-mail {
    display:flex;
    align-items: center;
    padding-bottom:10px;
}
.logo-mail span {
    font-size:1.5em;
}

footer form input::placeholder {
    color:var(--white);
}

.footer-content form {
    display: flex;
    justify-content: flex-end;
    position:relative;
}

.form-wrapper svg {
    font-size:4em;
}

.footer-content .logo img {
    max-width:150px;
    height:auto;
    filter: invert(1);
}
.footer-content .logo {
    margin-bottom:30px;
}

footer form input {
    padding: 10px;
    border-radius: 2px;
    border: 2.3px solid var(--white);
    background:var(--brown);
    color:var(--white);
}

footer form input + input { 
    margin: 0 10px;
}

.page-owner b + b {
    padding-left:5px;
}
.form-wrapper {
    margin-left:auto;
    width:max-content;
    position: relative;
}

.message-sended {
    transition: 2s ease;
    opacity: 1;
    position:absolute;
    left:0;
    right:0;
    bottom:-25%;
}

.message-sended.hide {
    opacity:0;
}

footer #btnEnviar {
    height: 40px;
    transition: 0.3s ease;
    font-weight: bold;
    text-transform: uppercase;
    transition:0.3s ease;
    cursor: pointer;
}

footer #btnEnviar:hover {
    opacity:0.8;
}

@media (min-width:1023px) {
   footer form input:first-child {
        margin-right: 10px;
    }
    .logo-mail span {
        padding-left:10px;
    }
}


@media (max-width:920px) {

    #btnEnviar {
        background: white;
        color: var(--brown);
        font-size:15px;
    }

    .footer-content form {
        flex-direction: column;
        justify-content: center;
    }
    footer form input {
        margin:10px !important;
    }
    .logo-mail span {
        font-size:1em;
    }
    .logo-mail {
        flex-direction: column;
    }
    .form-wrapper {
        margin:0 auto;
        padding-bottom:20px;
    }
    .message-sended {
        position:relative;
        bottom:0;
        display:flex;
        text-align: center;
    }
}

@media (max-width:640px) {
    .page-owner {
        font-size: 10px;
        text-align: center;
    }
    .footer-content .logo {
        display:flex;
        justify-content: center;
    }
    .footer-content {
        margin-top:40px;
    }
    .form-wrapper {
        width:260px;
    }
}

