.error-component {
    min-height:80vh;
    display:flex;
    justify-content: center;
    align-items: center;
}

.error-info {
    border:3px solid var(--border);
    padding:5%;
    display:flex;
    flex-direction: column;
    align-items: center;
    color:var(--brown);
    background:#f5f5f4;
}

.ico svg {
    font-size:5em;
}

.go-back svg {
    font-size: 2em;
    padding-left:10px;
}

.go-back {
    color: var(--brown);
    display: flex;
    align-items: center;
}

.go-back {
    text-decoration: none;
    transition:0.3s ease;
}

.go-back:hover {
    opacity:0.8;
    text-decoration:underline;
}