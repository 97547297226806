.product-container img {
    max-width: 300px;
    width: 100%;
    height: auto;
}

.catalog-wrapper {
    max-width: 80%;
    margin: 0 auto;
}


.product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 5%;
    gap: 20px;
    margin-bottom: 5%;
}

.catalog-title {
    text-transform: uppercase;
    border-bottom: 2px solid var(--border);
    justify-content: center;
    align-items: center;
    padding: 0 5px;
}

.catalog-info {
    display: flex;
    flex-direction: column;
}

.catalog-info .title,
.product-list .title {
    color: black;
    text-decoration: none;
    font-size: 1.2em;
    margin: 10px 0 5px;
    text-transform: uppercase;
}

.product-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    border: 2px solid var(--gray);
    padding: 5%;
    transition: 0.3s ease;
    cursor: pointer;
}

.product-container:hover {
    border: 2px solid var(--brown);
}

.product-container span {
    line-height: 1.5;
    color: var(--black);
}




/* Filtros */
.filter-wrapper h4 {
    font-size: 1.2em;
    border-bottom: 4px solid var(--brown);
    padding: 10px 0;
}

.catalog-container {
    display: flex;
    justify-content: flex-start;
}

.filter-wrapper {
    display: flex;
    width: 300px;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 30px;
}

#category-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 2px solid var(--border);
}

#category-container button {
    border: unset;
    font-size: 1em;
    font-weight: bold;
    background: unset;
    margin: 5px 0 10px 0;
    transition: 0.3s ease;
    cursor: pointer;
}

#category-container button:hover {
    opacity: 0.8;
}

.product-container.hide {
    display: none;
}

#category-container button svg {
    opacity: 0;
    transition: 0.3s ease;
}

#category-container button.active svg {
    opacity: 1;
}

@media (min-width:1024px) {
    .filter-mobile button {
        display: none;
    }

    .filter.sidebar {
        display: none;
    }

}



@media (max-width:1023px) {

    .catalog-wrapper {
        max-width: max-content;
    }

    .mobile-wrapper ul {
        padding: 0;
    }

    .filter-mobile svg {
        font-size: 20px;
        margin-right: 5px;
    }

    .filter-mobile button {
        display: flex;
        align-items: center;
    }

    .mobile-wrapper ul li:last-child {
        border-bottom: 1px solid var(--border);
    }

    .mobile-wrapper ul li {
        border-top: 1px solid var(--border);
    }

    .product-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .filter-column {
        display: flex;
        flex-direction: column;
    }

    .filter-column h4 {
        font-size: 1.5em;
    }

    .filter-mobile button,
    .filter-column button {
        border: unset;
        background: var(--brown);
        color: var(--white);
        padding: 10px;
        border-radius: 5px;
        font-weight: bold;
        transition: 0.3s ease;
        font-size: 15px;
    }

    .filter-mobile button:hover,
    .filter-column button:hover {
        opacity: 0.8;
    }

    .filter-column button {
        margin-bottom: 10px;
    }

    .filter-wrapper {
        display: none;
    }

    .filter.sidebar {
        position: relative;
    }

    .sidebar-wrapper {
        background: var(--white);
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        padding: 0 30px;
        z-index: 2;
        transition: 0.2s ease;
    }

    .sidebar .sidebar-wrapper {
        transform: translateX(-20em);
    }

    .sidebar.active .sidebar-wrapper {
        transform: translateX(0em);
    }


}

@media (max-width:820px) {
    .product-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .catalog-wrapper {
        padding: 0 10px;
    }

    .filter-column b {
        margin-bottom: 10px;
    }

    .catalog-info .title,
    .product-list .title {
        font-size: 1em;
    }
}

@media (max-width:470px) {
    .product-grid {
        grid-template-columns: 1fr;
        gap: 10px;
    }
}